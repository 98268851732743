<template>
  <div>
    <div class="container background-container">
      <div class="main-banner">
        <div class="row h-100">
          <div class="col-md-4">
            <h1 class="main-banner__title">
              Turn <br class="d-md-none d-block" />
              your <br />
              passion <br class="d-md-none d-block" />
              into <br />
              income
            </h1>
            <div class="main-banner__action mt-3">
              <Button size="sm" full-width @click="handleBeFoundClick">
                I'm in
              </Button>
            </div>
          </div>
          <div class="col-md-4 d-flex flex-column">
            <p class="main-banner__description">
              Publish your social media content into beautiful, unique, printed
              books and products to generate revenue.
            </p>
            <p>
              <b>
                Any topic. Any hobby. Any interest. By anyone, for everyone.
              </b>
            </p>

            <p class="mt-auto main-banner__link" @click="scrollDown">
              IT’S <b class="main-banner__underline">COMPLETELY FREE</b> & EASY
              <img
                class="ml-2"
                width="16"
                src="@/assets/icons/arrows/down.svg"
                alt="arrow-down"
              />
            </p>
          </div>
          <div class="col-md-4">
            <img class="w-100" src="@/assets/main.png" alt="main" />
          </div>
        </div>
      </div>
    </div>
    <div class="page container">
      <div class="row story mt-5 d-lg-block d-none">
        <div class="col-md-12 d-flex justify-content-end align-items-center">
          <div class="story__item d-flex flex-column">
            <img width="90" src="@/assets/phone.svg" alt="Content" />
            <p class="text-center mt-3">Content</p>
          </div>
          <img class="mx-5" width="32" src="@/assets/plus.svg" alt="plus" />
          <div class="story__item d-flex flex-column">
            <img width="90" src="@/assets/book.svg" alt="Product" />
            <p class="text-center mt-3">Product</p>
          </div>
          <img class="mx-5" width="32" src="@/assets/plus.svg" alt="plus" />
          <div class="story__item d-flex flex-column">
            <img width="90" src="@/assets/revenue.svg" alt="Income" />
            <p class="text-center mt-3 pl-2">Income</p>
          </div>
          <img class="mx-5" width="32" src="@/assets/equal.svg" alt="equal" />
          <div class="story__item d-flex flex-column align-items-center">
            <img width="90" src="@/assets/box.svg" alt="box" />
            <img
              class="mt-3 position-absolute"
              width="64"
              src="@/assets/logo-dark.svg"
              alt="logo"
            />
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-md-12 description mt-0">
          <img class="d-md-block d-none" src="@/assets/phone.svg" alt="phone" />

          <div class="description__container">
            <div class="description__header">
              <h4 class="d-flex align-items-end w-100">
                <img
                  class="d-md-none d-block"
                  src="@/assets/phone.svg"
                  alt="phone"
                  width="90"
                />
                <span class="description__underline text-uppercase w-100">
                  Social Media Content
                </span>
              </h4>
              <div class="description__button d-md-block d-none">
                <Button
                  class="mb-2"
                  full-width
                  size="sm"
                  @click="handleBeFoundClick"
                >
                  I have content
                </Button>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <p>
                  Whether you have 100,000 followers or 10M+ followers, you’ve
                  already done the hard work by building your following.
                  <br />
                  <br />
                  You’ve tirelessly generated and shared content rich posts that
                  your followers love and want.
                </p>
              </div>
              <div class="col-md-6">
                <p>
                  Why wouldn’t you reuse your content to work even harder and
                  smarter for you?
                  <br />
                  <br />
                  What if we said that with minimal effort and
                  <b>zero cost</b> your content can generate a considerable
                  revenue stream for you?
                </p>
                <div class="description__button d-md-none d-block mx-auto">
                  <Button
                    class="mb-2"
                    full-width
                    size="sm"
                    @click="handleBeFoundClick"
                  >
                    I have content
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        class="mb-5 mt-4 page__plus d-md-block d-none"
        width="32"
        src="@/assets/plus.svg"
        alt="plus"
      />

      <div class="row">
        <div class="col-md-12 description">
          <img class="d-md-block d-none" src="@/assets/book.svg" alt="book" />

          <div class="description__container">
            <div class="description__header">
              <h4 class="d-flex align-items-end w-100">
                <img
                  class="d-md-none d-block"
                  src="@/assets/book.svg"
                  alt="book"
                  width="90"
                />
                <span class="description__underline w-100">
                  UNIQUE BOOKS AND PRODUCTS
                </span>
              </h4>
              <div class="description__button d-md-block d-none">
                <Button
                  class="mb-2"
                  full-width
                  size="sm"
                  @click="handleBeFoundClick"
                >
                  Start producing products
                </Button>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <p>
                  Imagine taking your social content and publishing it into
                  beautiful, unique, printed books <b>within 8 weeks.</b>
                  <br />
                  <br />
                  Found specialise in doing <b>exactly that</b> and we don’t
                  stop there. We also design and produce stunning products that
                  will compliment your content perfectly.
                </p>
              </div>
              <div class="col-md-6">
                <p>
                  We take every word and every image from the content you
                  supply. Which we then turn into stunning and unique creator
                  branded products.
                  <br />
                  <br />
                  All designs and setup are <b>completely free</b> plus you will
                  own the designs. <b>Yes, really!</b>
                </p>
                <div class="description__button d-md-none d-block mx-auto">
                  <Button
                    class="mb-2"
                    full-width
                    size="sm"
                    @click="handleBeFoundClick"
                  >
                    Start producing products
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        class="mb-5 mt-4 page__plus d-md-block d-none"
        width="32"
        src="@/assets/plus.svg"
        alt="plus"
      />

      <div class="row">
        <div class="col-md-12 description">
          <img
            class="d-md-block d-none"
            src="@/assets/revenue.svg"
            alt="revenue"
          />

          <div class="description__container">
            <div class="description__header">
              <h4 class="d-flex align-items-end w-100">
                <img
                  width="90"
                  class="d-md-none d-block"
                  src="@/assets/revenue.svg"
                  alt="revenue"
                />
                <span class="description__underline w-100">
                  SALES AND REVENUE
                </span>
              </h4>
              <div class="description__button d-md-block d-none">
                <Button
                  class="mb-2"
                  full-width
                  size="sm"
                  @click="handleBeFoundClick"
                >
                  Yes to revenue
                </Button>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <p>
                  Via your own, unique creator store page on found.us we handle
                  all payments on all your orders.
                  <br />
                  <br />
                  Each month your sales royalties are
                  <b>transferred directly</b> into your bank account.
                </p>
              </div>
              <div class="col-md-6">
                <p>
                  All you have to do is promote your products on your social
                  channels and direct them to your products.
                  <br />
                  <br />
                  <b>It really is that easy.</b>
                </p>
                <div class="description__button d-md-none d-block mx-auto">
                  <Button
                    class="mb-2"
                    full-width
                    size="sm"
                    @click="handleBeFoundClick"
                  >
                    Yes to revenue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        class="mb-5 mt-4 page__plus d-md-block d-none"
        width="32"
        src="@/assets/equal.svg"
        alt="equal"
      />

      <div class="row">
        <div class="col-md-12 description">
          <img class="d-md-block d-none" src="@/assets/box.svg" alt="box" />

          <div class="description__container w-100">
            <div class="description__header w-100">
              <img
                class="d-md-none d-block"
                width="90"
                src="@/assets/box.svg"
                alt="box"
              />
              <span class="description__underline w-100">
                <img
                  class="mb-2"
                  width="76"
                  src="@/assets/logo-dark.svg"
                  alt="logo"
                />
              </span>
              <div class="description__button d-md-block d-none">
                <Button
                  class="mb-2"
                  full-width
                  size="sm"
                  @click="handleBeFoundClick"
                >
                  Let’s get started
                </Button>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <p>
                  Using our smart technology and cutting edge facilities we
                  print, produce, stitch and bind your books.
                  <br />
                  <br />
                  Not only will they look fantastic but they’ll feel great too.
                  <br />
                  <br />
                  Each product is made to order so there is zero stock outlay.
                  <b>Efficient.</b>
                </p>
              </div>
              <div class="col-md-6">
                <p>
                  We take the same approach when producing your unique products.
                  <br />
                  <br />
                  We then carefully ship your products across the globe landing
                  in the hands of your customers.
                  <br />
                  <br />
                  <b>
                    Imagine your content in your own book and on your unique
                    products
                  </b>
                </p>
                <div class="description__button d-md-none d-block mx-auto">
                  <Button
                    class="mb-2"
                    full-width
                    size="sm"
                    @click="handleBeFoundClick"
                  >
                    Let’s get started
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page container-fluid big-banner mt-md-4 mt-4">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2 class="big-banner__title">The Catch</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <h4 class="big-banner__sub-title">
              If it was easy <br />
              everyone <br />
              would do it, <br />
              <span class="bold-font">right?</span>
            </h4>
          </div>
          <div class="col-md-4">
            <p>
              <b>But it really is.</b> <br />
              It’s easy because we make it easy.
              <br />
              <br />
              Of course there’s a catch. <br />
              Supply your content then do what you do best, promote on your
              socials. <b>HARDLY A CATCH.</b>
            </p>
          </div>
          <div class="col-md-4 description__mobile">
            <div class="description__button">
              <Button
                class="pt-2"
                full-width
                size="sm"
                @click="handleBeFoundClick"
              >
                I’m ready to talk
              </Button>
            </div>
            <br />
            <!--
            <p class="mt-1 mb-0">
              <b>Call us on:</b>
              <a class="header__link mr-4" href="tel:+4401708836863">
                <span>+44 (0) 1708 836 863</span>
              </a>
            </p>
            -->
            <p>
              <b> Email: </b>
              <a class="header__link mr-4" href="mailto:hello@found.us">
                <span>hello@found.us</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="page container mt-5">
      <!--      <div class="row creators">-->
      <!--        <div class="col-md-3"></div>-->
      <!--        <div class="col-md-9 mb-5">-->
      <!--          <h4 class="creators__title">Who it’s working for</h4>-->
      <!--          <div class="description__button d-none">-->
      <!--            <Button full-width size="sm"> Success stories </Button>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="col-md-3 creators__image">-->
      <!--          <img-->
      <!--            class="cursor-pointer"-->
      <!--            width="240"-->
      <!--            height="240"-->
      <!--            src="@/assets/images/caroline.png"-->
      <!--            alt="trending-creator"-->
      <!--            @click="handleCreatorClick"-->
      <!--          />-->
      <!--        </div>-->
      <!--        <div class="col-md-5 creators__description">-->
      <!--          “Hi, I’m Caroline, aka Mississippi Kween.-->
      <!--          <br />I’m here to make cooking experiences easy and fun! Growing up on-->
      <!--          the Mississippi Gulf Coast I’m no stranger to good food packed with-->
      <!--          flavour and love. Thanks to signing up for UK-based digital platform,-->
      <!--          Found, I have sold thousands of copies of my first cookbook – and have-->
      <!--          not long released my second. The process is so simple, and I got so-->
      <!--          much support that it’s been amazing. It’s a real no brainer for any-->
      <!--          influencer who has social content and wants to benefit without any-->
      <!--          financial risk to them.”-->
      <!--        </div>-->
      <!--        <div class="col-md-4">-->
      <!--          <p-->
      <!--            class="mb-0 cursor-pointer mt-md-0 mt-3 text-uppercase"-->
      <!--            @click="handleCreatorClick"-->
      <!--          >-->
      <!--            <b> Caroline Davis</b>-->
      <!--          </p>-->
      <!--          <p class="cursor-pointer" @click="handleCreatorClick">-->
      <!--            @mississippi_kween-->
      <!--          </p>-->
      <!--          <p class="d-flex">-->
      <!--            <img-->
      <!--              class="mr-3"-->
      <!--              src="@/assets/icons/followers.svg"-->
      <!--              alt="followers"-->
      <!--            />-->
      <!--            Followers-->
      <!--            <span class="ml-auto">1.64 million</span>-->
      <!--          </p>-->
      <!--          <p class="d-flex">-->
      <!--            <img-->
      <!--              class="mr-3"-->
      <!--              src="@/assets/icons/small-book.svg"-->
      <!--              alt="small-book"-->
      <!--            />-->
      <!--            Products-->
      <!--            <span class="ml-auto">2</span>-->
      <!--          </p>-->
      <!--          <p class="d-flex creators__primary">-->
      <!--            <img class="mr-3" src="@/assets/icons/sales.svg" alt="sales" />-->
      <!--            Sales-->
      <!--            <span class="ml-auto">16,710</span>-->
      <!--          </p>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="row mt-5 reason">
        <div class="col-md-3">
          <h3 class="reason__title">
            Why choose <span class="reason__title-bold">Found?</span>
          </h3>
        </div>
        <div class="col-md-9 reason__description">
          <div class="d-flex mt-2">
            <p class="mr-2">/</p>
            <p>
              Published and selling in 8 weeks.
              <b class="reason__description-focus">Fast.</b>
            </p>
          </div>
          <div class="d-flex">
            <p class="mr-2">/</p>
            <p>
              Competitive royalties.
              <b class="reason__description-focus">Generous.</b>
            </p>
          </div>
          <div class="d-flex">
            <p class="mr-2">/</p>
            <p>
              Reuse your existing content.
              <b class="reason__description-focus">Smart.</b>
            </p>
          </div>
          <div class="d-flex">
            <p class="mr-2">/</p>
            <p>
              Worldwide cutting edge production facilities.
              <b class="reason__description-focus">Global.</b>
            </p>
          </div>
          <div class="d-flex">
            <p class="mr-2">/</p>
            <p>
              No design costs.
              <b class="reason__description-focus">Zero.</b>
            </p>
          </div>
          <div class="d-flex">
            <p class="mr-2">/</p>
            <p>
              Free PR opportunities.
              <b class="reason__description-focus">Coverage.</b>
            </p>
          </div>
          <div class="d-flex">
            <p class="mr-2">/</p>
            <p>
              Fully supported customer service by a team of friendly experts.
              <b class="reason__description-focus">Always.</b>
            </p>
          </div>
          <div class="d-flex">
            <p class="mr-2">/</p>
            <p>
              Earn upwards of 60k per year.
              <b class="reason__description-focus">Proven.</b>
            </p>
          </div>
          <div class="d-flex">
            <p class="mr-2">/</p>
            <p>
              No overheads or set up costs.
              <b class="reason__description-focus">None.</b>
            </p>
          </div>
          <div class="d-flex">
            <p class="mr-2">/</p>
            <p>
              Orders printed on demand - no stock required.
              <b class="reason__description-focus">Smart.</b>
            </p>
          </div>
          <div class="d-flex">
            <p class="mr-2">/</p>
            <p>
              Fulfilment and delivery is all taken care of.
              <b class="reason__description-focus">Done.</b>
            </p>
          </div>
          <div class="d-flex">
            <p class="mr-2">/</p>
            <p>
              Growing product portfolio.
              <b class="reason__description-focus">Futureproof.</b>
            </p>
          </div>
        </div>
      </div>

      <div class="row mt-5 pb-5 pipeline">
        <div
          class="col-md-12 d-flex align-items-center justify-content-end pipeline__center"
        >
          <div class="pipeline__item d-flex flex-column">
            <img
              class="pipeline__main-img"
              width="90"
              src="@/assets/phone.svg"
              alt="Content"
            />
            <p class="text-center mt-3">Content</p>
          </div>
          <img
            class="pipeline__resize mx-lg-auto mx-3"
            width="32"
            src="@/assets/plus.svg"
            alt="plus"
          />
          <div class="pipeline__item d-flex flex-column">
            <img
              class="pipeline__main-img"
              width="90"
              src="@/assets/book.svg"
              alt="Product"
            />
            <p class="text-center mt-3">Product</p>
          </div>
          <img
            class="pipeline__resize mx-lg-auto mx-3"
            width="32"
            src="@/assets/plus.svg"
            alt="plus"
          />
          <div class="pipeline__item d-flex flex-column">
            <img
              class="pipeline__main-img"
              width="90"
              src="@/assets/revenue.svg"
              alt="Income"
            />
            <p class="text-center mt-3">Income</p>
          </div>
          <img
            class="mx-auto d-lg-block d-none"
            width="32"
            src="@/assets/equal.svg"
            alt="equal"
          />
          <div
            class="pipeline__item d-none d-lg-flex flex-column align-items-center"
          >
            <img
              class="pipeline__main-img"
              width="90"
              src="@/assets/box.svg"
              alt="box"
            />
            <img
              class="mt-3 position-absolute"
              width="64"
              src="@/assets/logo-dark.svg"
              alt="logo"
            />
          </div>

          <div class="description__button ml-auto d-lg-block d-none">
            <Button full-width size="sm" @click="handleBeFoundClick">
              I’m ready to talk
            </Button>
          </div>
        </div>

        <div
          class="pipeline__margin d-lg-none d-flex flex-column align-items-center w-100 justify-content-center"
        >
          <img class="mt-5" width="32" src="@/assets/equal.svg" alt="equal" />
          <img
            class="mt-5 pipeline__main-mg"
            width="90"
            src="@/assets/box.svg"
            alt="box"
          />
          <img
            class="mt-3"
            width="64"
            src="@/assets/logo-dark.svg"
            alt="logo"
          />
        </div>
      </div>

      <div class="row follows mt-5">
        <div class="col-md-12 d-flex follows__wrap">
          <h3 class="follows__title">
            Follow us <span class="follows__title-bold">On Social</span>
          </h3>

          <div class="follows__socials">
            <a
              v-for="(link, key) in socialLinks"
              :key="key"
              :href="link"
              target="_blank"
              class="ml-4"
            >
              <img :src="getImageItem(key)" :alt="key" width="30" />
            </a>
          </div>
        </div>

        <div class="col-md-12 follows__images">
          <img src="@/assets/images/socials/0.png" alt="socials" />
          <img src="@/assets/images/socials/4.png" alt="socials" />
          <img src="@/assets/images/socials/8.png" alt="socials" />
          <img src="@/assets/images/socials/1.png" alt="socials" />
          <img src="@/assets/images/socials/5.png" alt="socials" />
          <img src="@/assets/images/socials/10.png" alt="socials" />
          <img src="@/assets/images/socials/2.png" alt="socials" />
          <img src="@/assets/images/socials/6.png" alt="socials" />
          <img src="@/assets/images/socials/11.png" alt="socials" />
          <img src="@/assets/images/socials/3.png" alt="socials" />
          <img src="@/assets/images/socials/7.png" alt="socials" />
          <img src="@/assets/images/socials/12.png" alt="socials" />
        </div>
      </div>

      <div class="row mt-5 mb-4">
        <h3 class="col-md-12 become text-center">
          #become<span class="become__primary">found</span>
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { head } from 'lodash';
import { mapGetters } from 'vuex';
import Button from '@/components/Elements/Button';
import socialLinks from '@/misc/socialLinks';

export default {
  name: 'HowItWorksPage',

  components: {
    Button
  },

  data() {
    return {
      socialLinks
    };
  },

  head: {
    title: `Welcome to Found! Discover today's social creators`,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content:
          'Found: Publishing creative content by anyone, for everyone. Discover the top social stars and get creative with their recipes, books and more!'
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: require('@/assets/logo.svg')
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        content: require('@/assets/logo.svg')
      }
    ]
  },

  computed: {
    ...mapGetters({
      isMobile: 'main/isMobile'
    })
  },

  methods: {
    handleCreatorClick() {
      this.$router.push({ path: '/creators/mississippi_kween' });
    },
    getImageItem(key) {
      return require(`@/assets/icons/social/${key}.svg`);
    },
    handleBeFoundClick() {
      this.$router.push({ path: '/be-found' });
    },
    scrollDown() {
      const HEADER_HEIGHT = this.isMobile ? 95 : 5;
      const elementToScroll = head(
        document.getElementsByClassName('description')
      );
      const elementPosition = elementToScroll.getBoundingClientRect().top;
      const offsetPosition = elementPosition - HEADER_HEIGHT;

      window.scrollBy({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.creator__spotlights {
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-width-lg) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.red-title {
  color: $red-color;
}

.bold-font {
  font-family: 'Poppins-Bold', sans-serif;
}

.page,
.container {
  b {
    font-family: 'Poppins-SemiBold', sans-serif;
  }

  &__plus {
    margin-left: 78px;
  }
}

.main-banner {
  padding: 15px;
  background-color: $white-color;
  height: 380px;
  margin-top: 35px;
  color: $dark-color;

  &__title {
    font-family: 'Poppins-Bold', sans-serif;
    padding-top: 35px;
    text-align: right;
    text-transform: uppercase;
  }

  &__action {
    width: 115px;
    margin-left: auto;
  }

  &__description {
    padding-top: 35px;
    text-align: left;
  }

  &__link {
    margin-top: auto;
    cursor: pointer;
    line-height: 28px;
    font-size: 18px;
  }

  &__underline {
    border-bottom: 2px solid $primary-color;
  }
}

.description {
  display: flex;
  align-items: flex-start;

  img {
    margin-right: 35px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: $primary-color;
    border-bottom: 2px solid $primary-color;
  }

  &__button {
    width: 240px;
  }

  &__underline {
    font-family: 'Poppins-SemiBold', sans-serif;
  }
}

.big-banner {
  height: 500px;
  background-color: $white-color;
  color: $dark-color;

  a {
    text-decoration: none;
    color: $dark-color;
  }

  &__title {
    font-family: 'Poppins-Bold', sans-serif;
    font-size: 196px;
    text-align: center;
    text-transform: uppercase;
    color: $primary-color;
  }
  &__sub-title {
    font-size: 35px;
    text-transform: uppercase;
    font-family: 'Poppins-SemiBold', sans-serif;
    text-align: right;
  }
}

.creators {
  &__title {
    color: $primary-color;
    margin: 0 auto;
    font-family: 'Poppins-SemiBold', sans-serif;
    font-size: 40px;
    text-transform: uppercase;
  }

  &__image {
    text-align: right;
  }

  &__description {
    font-style: italic;
  }

  &__primary {
    color: $primary-color;
    font-family: 'Poppins-SemiBold', sans-serif;
  }
}

.reason {
  &__title {
    text-align: right;
    color: $primary-color;
    font-family: 'Poppins-SemiBold', sans-serif;
    font-size: 56px;
    text-transform: uppercase;

    &-bold {
      color: $white-color;
      font-family: 'Poppins-Bold', sans-serif;
    }
  }

  &__description-focus {
    color: $primary-color;
    font-family: 'Poppins-SemiBold', sans-serif;
  }
}

.follows {
  &__title {
    color: $primary-color;
    font-family: 'Poppins-SemiBold', sans-serif;
    font-size: 56px;
    text-transform: uppercase;

    &-bold {
      color: $white-color;
      font-family: 'Poppins-Bold', sans-serif;
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__images {
    grid-gap: 5px;
    display: grid;
    grid-template-rows: 180px 180px;
    grid-auto-flow: column;
    overflow-x: hidden;
    height: 420px;
    width: 100%;

    img {
      width: 180px;
      height: 180px;
    }
  }
}

.become {
  color: $white-color;
  font-family: 'Poppins-SemiBold', sans-serif;

  &__primary {
    color: $primary-color;
  }
}

.story,
.pipeline {
  padding-bottom: 35px;

  &__item {
    position: relative;

    p,
    .position-absolute {
      position: absolute;
      top: 95px;
      width: 100%;
      text-align: center;
    }
  }
}

.pipeline {
  padding-bottom: 27px;

  &__item {
    p,
    .position-absolute {
      top: 95px;
    }
  }
}

@media (max-width: $screen-width-xl) {
  .creators__image {
    img {
      width: 100%;
    }
  }

  .big-banner__title {
    font-size: 140px;
  }

  .follows__images {
    overflow-x: scroll;
  }

  .reason__title {
    font-size: 46px;
  }
}

@media (max-width: $screen-width-lg) {
  .big-banner__title {
    font-size: 110px;
  }

  .reason__title {
    font-size: 36px;
  }

  .pipeline__center {
    justify-content: center !important;
  }
}

@media (max-width: $screen-width-md) {
  .main-banner {
    height: auto;
    min-height: 380px;
    margin-top: 15px;

    &__title {
      line-height: 56px;
      text-align: left;
      font-size: 56px;
      padding-top: 25px;
    }

    &__action {
      margin-right: auto;
      margin-left: 0;
    }

    &__link {
      font-size: 22px;
    }
  }

  .description {
    margin-top: 55px;

    &__mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__underline {
      border-bottom: 2px solid $primary-color;
      padding-bottom: 8px;
    }

    &__header {
      font-family: 'Poppins-SemiBold', sans-serif;
      border-bottom: none;
    }
  }

  .big-banner {
    min-height: 500px;
    height: auto;

    &__title {
      font-size: 54px;
      text-align: left;
      line-height: 1;
      padding-top: 5px;
    }

    &__sub-title {
      text-align: left;
    }
  }

  .creators {
    &__title {
      font-size: 32px;
    }

    &__image {
      text-align: center;
      margin-bottom: 25px;
      margin-top: -25px;

      img {
        width: 240px;
      }
    }
  }

  .reason {
    &__title {
      text-align: left;
    }
  }

  .follows {
    &__wrap {
      flex-direction: column;
    }
    &__socials {
      margin-right: auto;
      margin-left: 0;
      margin-bottom: 20px;

      :first-child {
        margin-left: 0 !important;
      }
    }
  }

  .pipeline {
    &__resize {
      width: 24px;
      height: 24px;
    }

    &__margin {
      margin-top: 40px;
    }

    &__main-img {
      width: 64px;
    }
  }
}
</style>
